import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./Lang";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyles from "./components/globalStyle/GlobalStyle";
import { AppProvider } from "./components/context/AppProvider";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider>
      <BrowserRouter>
        <ToastContainer />
        <GlobalStyles />
        <App />
      </BrowserRouter>
    </AppProvider>
  </React.StrictMode>
);

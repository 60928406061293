import React from "react";
import "./infoServices.css";
import { IoCloseCircleOutline } from "react-icons/io5";

const InfoServices = ({ workId, earthWorks, setInfoServices }) => {
  const itemWithId = earthWorks.find((item) => item.id === workId);

  if (!itemWithId) {
    return setInfoServices(false);
  }

  const handleClose = () => {
    setInfoServices(false);
  };

  return (
    <div id="info_services">
      <IoCloseCircleOutline className="close" onClick={handleClose} />
      <div className="info_services">
        <div className="info_services_itemWithId">
          <div className="img_itemWithId_1">
            <img src={itemWithId.img} alt="itemWithId" />
          </div>
          {itemWithId.img_2 && (
            <div className="img_itemWithId_2">
              <img src={itemWithId.img_2} alt="itemWithId" />
            </div>
          )}
          <h2>{itemWithId.title}</h2>
          {itemWithId.title_text && (
            <h2 className="title_text_2">
              {itemWithId.title_text}
              <span></span>
            </h2>
          )}
          {itemWithId.text_1 && <p>{itemWithId.text_1}</p>}
          {itemWithId.text_2 && <p>{itemWithId.text_2}</p>}
          {itemWithId.text_3 && <p>{itemWithId.text_3}</p>}
          {itemWithId.text_4 && <p>{itemWithId.text_4}</p>}
          {itemWithId.text_5 && <p>{itemWithId.text_5}</p>}
          {itemWithId.text_6 && <p>{itemWithId.text_6}</p>}

          {itemWithId.title_text_2 && (
            <>
              <br />
              <br />
            </>
          )}
          {itemWithId.title_text_2 && <h2>{itemWithId.title}</h2>}
          {itemWithId.title_text_2 && (
            <h2 className="title_text_2">
              {itemWithId.title_text_2}
              <span></span>
            </h2>
          )}
          {itemWithId.text_7 && <p>{itemWithId.text_7}</p>}
          {itemWithId.text_8 && <p>{itemWithId.text_8}</p>}
          {itemWithId.text_9 && <p>{itemWithId.text_9}</p>}
          {itemWithId.text_10 && <p>{itemWithId.text_10}</p>}
          {itemWithId.text_11 && <p>{itemWithId.text_11}</p>}
          {itemWithId.text_12 && <p>{itemWithId.text_12}</p>}
          {itemWithId.text_13 && <p>{itemWithId.text_13}</p>}
          {itemWithId.text_14 && <p>{itemWithId.text_14}</p>}
          {itemWithId.text_15 && <p>{itemWithId.text_15}</p>}
          {itemWithId.text_16 && <p>{itemWithId.text_16}</p>}
          {itemWithId.text_17 && <p>{itemWithId.text_17}</p>}
          {itemWithId.text_18 && <p>{itemWithId.text_18}</p>}
          {itemWithId.text_19 && <p>{itemWithId.text_19}</p>}
        </div>
      </div>
    </div>
  );
};

export default InfoServices;

import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from "../../img/logo.webp";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Contact from "../../page/contact/Contact";
import Copyrights from "../../components/copyrights/Copyrights";

const Navbar = ({ setDirection, direction }) => {
  const [isChecked, setChecked] = useState(false);
  const [isPathname, setPathname] = useState("");

  const handleToggle = () => {
    setChecked(!isChecked);
  };

  const { i18n, t } = useTranslation();

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    setDirection("ltr");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    setDirection("rtl");
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/") {
      setPathname(false);
    } else {
      setPathname(true);
    }
  }, [location.pathname]);

  const navbarLink = [
    { to: "header", name: t("home") },
    { to: "about", name: t("about_us") },
    { to: "services", name: t("our_services") },
    { to: "projects", name: t("our_projects") },
  ];

  return (
    <>
      <nav>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />

        {isPathname ? (
          <NavLink to="/" className="logo">
            <img src={logo} alt="logo" />
          </NavLink>
        ) : (
          <Link
            to="header"
            spy={true}
            smooth={true}
            offset={-200}
            duration={500}
            className="logo"
          >
            <img src={logo} alt="logo" />
          </Link>
        )}

        <ul className="links" onClick={handleToggle}>
          {isPathname ? (
            <NavLink to="/">{t("home")}</NavLink>
          ) : (
            navbarLink.map((item, i) => (
              <li key={i}>
                <Link
                  onClick={handleToggle}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  offset={-200}
                  duration={500}
                >
                  {item.name}
                </Link>
              </li>
            ))
          )}

          {direction == "ltr" ? (
            <li>
              <button className="ChangeLang" onClick={ChangeAR}>
                AR
              </button>
            </li>
          ) : (
            <li>
              <button className="ChangeLang" onClick={ChangeEN}>
                EN
              </button>
            </li>
          )}
        </ul>

        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>

      <Outlet />

      <Contact />
      <Copyrights />
    </>
  );
};

export default Navbar;

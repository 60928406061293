import React from "react";
import "./header.css";
import headerImg from "../../img/header.webp";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div id="header">
      <div className="header">
        <img src={headerImg} alt="header Img" />

        <div className="text">
          <div className="top">
            <h1>{t("nam_dam")}</h1>
            <h2>{t("trading_contracting")}</h2>
            <p>{t("info_header")}</p>

            <div className="border">
              <span className="border_1"></span>
              <span className="border_2"></span>
            </div>
          </div>
          <div className="bottom">
            <div className="text_buttom">
              <p>{t("be_touch_cheek")}</p>
              <Link
                to="projects"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >
                {t("our_projects")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

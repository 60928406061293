import React, { useEffect, useState } from "react";
import "./allProjects.css";
import { useAppContext } from "../../../components/context/AppProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AllProjects = () => {
  const [allDataViewProject, setAllDataViewProject] = useState([]);
  const [viewProjectCount, setViewProjectCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { direction } = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataViewProject = async () => {
    const formDataViewViewProject = new FormData();
    formDataViewViewProject.append("validation", validation);

    try {
      const responseViewProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewViewProject,
        }
      );
      const dataViewProject = await responseViewProject.json();
      setAllDataViewProject(dataViewProject);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataViewProjectCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responseViewProjectCount = await fetch(
        `${LinkLikeBachend}read/view_project_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataViewProjectCount = await responseViewProjectCount.text();

      setViewProjectCount(dataViewProjectCount);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewViewProjectPage = new FormData();
    formDataViewViewProjectPage.append("validation", validation);
    formDataViewViewProjectPage.append("page", currentPage + 1);

    try {
      const responseViewProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewViewProjectPage,
        }
      );
      const dataViewProject = await responseViewProject.json();

      setTimeout(() => {
        setAllDataViewProject((prevData) => prevData.concat(dataViewProject));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataViewProject();
    fetchDataViewProjectCount();
  }, []);

  const extractText = (text) => {
    const pattern = /<p>.*?<\/p>/;
    const matchResult = pattern.exec(text);

    return (
      matchResult &&
      `${matchResult[0].substring(0, 20)}${
        matchResult[0].length > 20 ? "..." : ""
      }`
    );
  };

  const handelIdProject = (idProject) => {
    navigate(`/project/${idProject}`);
  };

  return (
    <div id="all_projects">
      <div className="all_projects">
        <div className="all_project">
          {allDataViewProject &&
            allDataViewProject.map((item, i) => (
              <div
                className="project"
                key={i}
                onClick={() => {
                  handelIdProject(item.id);
                }}
              >
                <img
                  src={`${LinkLikeBachend}${item.img_project}`}
                  alt={item.title_project_en}
                />

                {direction == "ltr" ? (
                  <>
                    <p>{item.title_project_en}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: extractText(item.description_project_en),
                      }}
                    />
                  </>
                ) : (
                  <>
                    <p>{item.title_project_ar}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: extractText(item.description_project_ar),
                      }}
                    />
                  </>
                )}

                <button>{t("read_more")}</button>
              </div>
            ))}
        </div>
        <div className="div_load_more">
          {allDataViewProject &&
            allDataViewProject.length > 0 &&
            parseInt(viewProjectCount) !== allDataViewProject.length && (
              <button
                className="load_more"
                onClick={handleLoadMore}
                disabled={isDisabledLoadMore}
                style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
              >
                {!isDisabledLoadMore ? "Load More" : "Loading..."}
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default AllProjects;

import React, { useState } from "react";
import background_img from "../../../img/background_img.webp";
import "./addProject.css";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

const AddProject = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const [editorDataEN, setEditorDataEN] = useState(null);
  const [editorDataAR, setEditorDataAR] = useState(null);

  const [editorConfigEN, setEditorConfigEN] = useState({
    placeholder: "description en",
  });
  const [editorConfigAR, setEditorConfigAR] = useState({
    placeholder: "description ar",
    direction: "rtl",
  });

  const handleSendcontentEN = (newContentEN) => {
    setEditorDataEN(newContentEN);
  };
  const handleSendcontentAR = (newContentAR) => {
    setEditorDataAR(newContentAR);
  };

  const [addProject, setAddProject] = useState({
    title_project_en: "",
    title_project_ar: "",
  });

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    setAddProject((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setSelectedImage(file);
  };

  const imageUrl = selectedImage
    ? URL.createObjectURL(selectedImage)
    : background_img;

  const handleAddProject_admin = async (e) => {
    e.preventDefault();
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("img_project", selectedImage);
    FormDataSubmit.append("editorDataEN", editorDataEN);
    FormDataSubmit.append("editorDataAR", editorDataAR);

    Object.entries(addProject).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    const response = await fetch(`${LinkLikeBachend}create/project.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        let img_project = document.querySelector("#img_project");
        img_project.value = "";

        toast.success("Data added successfully");

        setSelectedImage(null);
        setDisabledSubmit(false);

        setAddProject({
          title_project_en: "",
          title_project_ar: "",
        });

        let jodit_wysiwyg = document.querySelectorAll(".jodit-wysiwyg");

        jodit_wysiwyg.forEach(function (element) {
          element.innerHTML = "";
        });
      } else {
        toast.error("Error when adding data");

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when adding data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledSubmit(false);
    }
  };

  // console.log(addProject);
  // console.log(selectedImage);

  return (
    <div className="project_admin">
      <h2>Add Post</h2>
      <form
        className="project_form"
        onSubmit={handleAddProject_admin}
        encType="multipart/form-data"
      >
        <div className="div_img_project">
          <input
            type="file"
            name="img_project"
            id="img_project"
            required
            accept="image/*"
            onChange={handleFileChange}
          />
          {imageUrl && (
            <div>
              <img src={imageUrl} alt="background_img" />
            </div>
          )}
        </div>

        <div className="data_form">
          <input
            type="text"
            name="title_project_en"
            id="title_project_en"
            placeholder="title en"
            required
            value={addProject.title_project_en || ""}
            onChange={handleChangeUpdate}
          />

          <input
            type="text"
            name="title_project_ar"
            id="title_project_ar"
            placeholder="title ar"
            required
            value={addProject.title_project_ar || ""}
            onChange={handleChangeUpdate}
          />
        </div>

        <div className="data_form">
          <div className="description">
            <label htmlFor="description">description en</label>
            <JoditEditor
              name="description_project_en"
              id="description_project_en"
              placeholder="description en"
              value={addProject.description_project_en || ""}
              onChange={handleSendcontentEN}
              config={editorConfigEN}
              required
            />
          </div>

          <div className="description">
            <label htmlFor="description">description ar</label>
            <JoditEditor
              value={addProject.description_project_ar || ""}
              onChange={handleSendcontentAR}
              id="description_project_ar"
              name="description_project_ar"
              placeholder="description ar"
              config={editorConfigAR}
              required
            />
          </div>
        </div>

        <input
          type="submit"
          value="Add"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        />
      </form>
    </div>
  );
};

export default AddProject;

import React, { useEffect, useState } from "react";
import "./viewProject.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ViewProject = () => {
  const [allDataViewProject, setAllDataViewProject] = useState([]);
  const [viewProjectCount, setViewProjectCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataViewProject = async () => {
    const formDataViewViewProject = new FormData();
    formDataViewViewProject.append("validation", validation);

    try {
      const responseViewProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewViewProject,
        }
      );
      const dataViewProject = await responseViewProject.json();
      setAllDataViewProject(dataViewProject);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataViewProjectCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responseViewProjectCount = await fetch(
        `${LinkLikeBachend}read/view_project_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataViewProjectCount = await responseViewProjectCount.text();

      setViewProjectCount(dataViewProjectCount);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewViewProjectPage = new FormData();
    formDataViewViewProjectPage.append("validation", validation);
    formDataViewViewProjectPage.append("page", currentPage + 1);

    try {
      const responseViewProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewViewProjectPage,
        }
      );
      const dataViewProject = await responseViewProject.json();

      setTimeout(() => {
        setAllDataViewProject((prevData) => prevData.concat(dataViewProject));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataViewProject();
    fetchDataViewProjectCount();
  }, []);

  const handleClickUpdate = async (idProject) => {
    navigate(`/dashboard/update-project/${idProject}`);
  };

  const handleClickDelete = async (idProject) => {
    setDisabledLoadMore(true);

    const FormDataIdProject = new FormData();
    FormDataIdProject.append("validation", validation);
    FormDataIdProject.append("idProject", idProject);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_project.php`,
      {
        method: "POST",
        body: FormDataIdProject,
      }
    );

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The data has been deleted successfully");

        fetchDataViewProject();
        fetchDataViewProjectCount();

        setDisabledLoadMore(false);
      } else {
        toast.error("Error when deleting data");

        console.log(resultText);
        setDisabledLoadMore(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when deleting data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledLoadMore(false);
    }
  };

  //   console.log(allDataViewProject);
  //   console.log(viewProjectCount);

  return (
    <div className="ViewProject_admin_view">
      <h2>All project</h2>

      <div className="all_view_project">
        {allDataViewProject && allDataViewProject.length > 0 ? (
          <>
            {allDataViewProject.map((ViewProject, index) => (
              <div className="view_project" key={index}>
                <div className="img_project">
                  <img
                    src={`${LinkLikeBachend}${ViewProject.img_project}`}
                    alt="title_project_en"
                  />
                </div>

                <p>{ViewProject.title_project_en}</p>
                <div
                  className="description_project_en"
                  dangerouslySetInnerHTML={{
                    __html: ViewProject.description_project_en,
                  }}
                />

                <hr />

                <p>{ViewProject.title_project_ar}</p>
                <div
                  className="description_project_ar"
                  dangerouslySetInnerHTML={{
                    __html: ViewProject.description_project_ar,
                  }}
                />
                <p style={{ direction: "rtl", margin: "1rem auto" }}>
                  {ViewProject.date_add}
                </p>

                <div className="action_post">
                  <button onClick={() => handleClickUpdate(ViewProject.id)}>
                    Update
                  </button>
                  <button
                    disabled={isDisabledLoadMore}
                    style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                    onClick={() => handleClickDelete(ViewProject.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p>No ViewProject data available.</p>
        )}
      </div>

      <div className="div_load_more">
        {allDataViewProject &&
          allDataViewProject.length > 0 &&
          parseInt(viewProjectCount) !== allDataViewProject.length && (
            <button
              className="load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "Load More" : "Loading..."}
            </button>
          )}
      </div>
    </div>
  );
};

export default ViewProject;

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "./services.css";
import { useTranslation } from "react-i18next";
import services_1 from "../../img/services_1.webp";
import services_2 from "../../img/services_2.webp";
import services_3 from "../../img/services_3.webp";
import services_3_3 from "../../img/services_3-3.webp";
import services_4 from "../../img/services_4.webp";
import InfoServices from "./infoServices/InfoServices";

const Services = () => {
  const { t } = useTranslation();
  const [infoServices, setInfoServices] = useState(false);
  const [workId, setWorkId] = useState(0);

  let earthWorks = [
    {
      id: 1,
      img: services_1,
      title: t("earth_works"),
      text_1: t("excavation_works"),
      text_2: t("micro_tunneling"),
      text_3: t("cut_fill_works"),
      text_4: t("grading_works"),
    },
    {
      id: 2,
      img: services_2,
      title: t("underground_utilities"),
      title_text: t("wet_utilities"),
      title_text_2: t("dry_utilities"),
      text_1: t("foul_sewer_drainage_networks"),
      text_2: t("storm_water_drainage_networks"),
      text_3: t("tse_networks"),
      text_4: t("potable_water_lines"),
      text_5: t("fire_suppression_services"),
      text_6: t("mainline_services_structures"),
      text_7: t("foundations"),
      text_8: t("underground_electrical_works"),
      text_9: t("underground_telecommunication_works"),
      text_10: t("qaf_qatar_armed_forces"),
      text_11: t("its_intelligent_transport_systems"),
      text_12: t("cable_laying"),
      text_13: t("pole_foundation"),
      text_14: t("underground_duct_works"),
      text_15: t("street_sign_boards"),
      text_16: t("street_lighting_works"),
      text_17: t("steel_fencing"),
      text_18: t("substations"),
    },
    {
      id: 3,
      img: services_3,
      img_2: services_3_3,
      title: t("road_works"),
      text_1: t("asphalt_milling"),
      text_2: t("subgrade_works"),
      text_3: t("subbase_works"),
      text_4: t("asphalting_works"),
      text_5: t("road_marking"),
      text_6: t("kerbs_gutters"),
      text_7: t("parking_bay_side_walk_median_islands_works"),
      text_8: t("bus_stop_lanes"),
      text_9: t("brick_paving_works"),
    },
    {
      id: 4,
      img: services_4,
      title: t("flooring_works"),
      text_1: t("epoxy_flooring_works"),
      text_2: t("concrete_flooring"),
      text_3: t("rubber_flooring"),
      text_4: t("colored_stamped_concrete_flooring"),
    },
  ];

  const handleIDEarthWorks = (workId) => {
    setInfoServices(true);
    setWorkId(workId);
  };

  return (
    <div id="services">
      <div className="services">
        <div className="services_top">
          <h2>{t("our_services")}</h2>
          <p>{t("about_top_p")}</p>
        </div>
      </div>
      <div className="services_bottom">
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            1023: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 3,
            },
            530: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
        >
          {earthWorks.map((work, i) => (
            <SwiperSlide
              key={i}
              virtualIndex={i}
              onClick={() => {
                handleIDEarthWorks(work.id);
              }}
            >
              <img src={work.img} alt={work.text} />
              <p>
                {work.title.length > 20
                  ? `${work.title.substring(0, 20)}...`
                  : work.title}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {infoServices && (
        <InfoServices
          workId={workId}
          earthWorks={earthWorks}
          setInfoServices={setInfoServices}
        />
      )}
    </div>
  );
};

export default Services;

import React from "react";
import "./about.css";
import { useTranslation } from "react-i18next";
import about_center_img from "../../img/about_center_img.webp";
import about_bottom_1 from "../../img/about_bottom_1.webp";
import about_bottom_2 from "../../img/about_bottom_2.webp";
import about_logo from "../../img/about_logo.webp";

const About = () => {
  const { t } = useTranslation();

  return (
    <div id="about">
      <div className="about">
        <div className="about_top">
          <h2>{t("about_us")}</h2>
          <p>{t("about_top_p")}</p>
        </div>
        <div className="about_center">
          <div className="about_center_img">
            <img src={about_center_img} alt="about_center_img" />
          </div>
          <div className="about_center_p">
            <p>{t("about_center_p_1")}</p>
            <p>{t("about_center_p_2")}</p>

            <div className="border">
              <span className="border_1"></span>
              <span className="border_2"></span>
            </div>
          </div>
        </div>
        <div className="about_bottom">
          <p>{t("about_center_p_3")}</p>
          <p>{t("about_center_p_4")}</p>
        </div>
      </div>

      <div className="img_bottom">
        <div className="img_bottom_left">
          <img src={about_bottom_1} alt="about_bottom_1" />
        </div>
        <div className="img_bottom_right">
          <div className="image_about_bottom_2">
            <img src={about_bottom_2} alt="about_bottom_2" />
          </div>
          <img src={about_logo} alt="about_logo" className="about_logo" />
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useEffect, useState } from "react";
import "./contact.css";
import { Link } from "react-scroll";
import logo from "../../img/logo.webp";
import {
  FaArrowUp,
  FaPhoneAlt,
  FaInstagramSquare,
  FaLinkedin,
  FaFacebookSquare,
} from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const Contact = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [isPathname, setPathname] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/") {
      setPathname(false);
    } else {
      setPathname(true);
    }
  }, [location.pathname]);

  const navbarLink = [
    { to: "header", name: t("home") },
    { to: "about", name: t("about_us") },
    { to: "services", name: t("our_services") },
    { to: "projects", name: t("our_projects") },
  ];
  return (
    <div id="contact">
      <div className="contact">
        <div className="all_contact">
          <div className="left">
            {isPathname ? (
              <NavLink to="/" className="logo">
                <img src={logo} alt="logo" />
              </NavLink>
            ) : (
              <Link
                to="header"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                className="logo"
              >
                <img src={logo} alt="logo" />
              </Link>
            )}
          </div>

          <div className="center">
            <a className="whatsapp" href="tel:+97444934096" target="_blank">
              <FaPhoneAlt />
              +974-44934096
            </a>
            <a className="whatsapp" href="tel:+97455568595" target="_blank">
              <FaPhoneAlt />
              +974-55568595
            </a>

            <a className="whatsapp" href="tel:+97455517972" target="_blank">
              <FaPhoneAlt />
              +974-55517972
            </a>

            <a
              className="mailto"
              href="mailto:Info@Damcontracting.org"
              target="_blank"
            >
              <MdOutlineEmail /> Info@Damcontracting.org
            </a>

            <div className="social_media">
              <a
                href="https://www.instagram.com/damtc.qa/?igsh=MXNvY2VhbHF5amwwaw%3D%3D"
                target="_blank"
              >
                <FaInstagramSquare />
              </a>
              <a
                href="https://www.linkedin.com/company/dam-trading-contracting-damtc/about/"
                target="_blank"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://web.facebook.com/people/DAMTC-DAM-Trading-Contracting/61554462886103/"
                target="_blank"
              >
                <FaFacebookSquare />
              </a>
            </div>
          </div>

          <div className="right">
            <ul className="links">
              {isPathname ? (
                <NavLink to="/">{t("home")}</NavLink>
              ) : (
                navbarLink.map((item, i) => (
                  <li key={i}>
                    <Link
                      to={item.to}
                      spy={true}
                      smooth={true}
                      offset={-200}
                      duration={500}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="top_arrow" onClick={scrollToTop}>
        <FaArrowUp />
      </div>
    </div>
  );
};

export default Contact;

export const arabicDataAr = {
  home: "الرئيسية",
  about_us: "من نحن",
  our_services: "خدماتنا",
  our_projects: "مشاريعنا",
  nam_dam: "دام",
  trading_contracting: "للتجارة والمقاولات",
  info_header:
    "هي شركة مدنية وإنشاءات، وقد اكتسبت سمعة قوية في تقديم خدمات عالية الجودة، في الوقت المحدد، وبأسعار تنافسية",
  be_touch_cheek: "كن على اتصال",
  about_top_p: "دام للتجارة والمقاولات ذ.م.م",
  about_center_p_1:
    "تأسست شركة دام وتأسست في الدوحة، قطر كشركة مرافق صغيرة في 4 مارس 2019.",
  about_center_p_2:
    "ومنذ ذلك الحين، نمت شركة دام لتصبح أحد شركات المرافق والبنية التحتية المرموقة والرائدة في دولة قطر.",
  about_center_p_3:
    "تلتزم شركة دام بتجاوز توقعات عملائنا والمقاولين الرئيسيين والموردين من خلال تطوير العلاقات من خلال النزاهة والكفاءة المهنية والخدمة التي تنتج مشاريع بناء بأعلى جودة.",
  about_center_p_4:
    "يتكون فريق شركتنا من المتفوقين الذين يحرصون على حصول عملائنا على الأفضل. شركتنا هي أفراد ذوو جودة عالية ينتجون مشاريع عالية الجودة على يد مهندسين محترفين، والتميز هو هدفنا وكذلك ضمان تحقيق عملائنا لأهدافهم.",
  load_more: "تحميل المزيد",
  loading: "تحميل...",
  read_more: "قرائة المزيد",
  ////////////////////////////
  earth_works: "اعمال الارض",
  excavation_works: "أعمال الحفر",
  micro_tunneling: "الأنفاق الصغيرة",
  cut_fill_works: "أعمال القطع والتعبئة",
  grading_works: "أعمال الدرجات",
  ////////////////////////////
  underground_utilities: "تحت المرافق الأرضية",
  wet_utilities: "المرافق الرطبة",
  foul_sewer_drainage_networks: "شبكات الصرف الصحي الفاسدة",
  storm_water_drainage_networks: "شبكات صرف مياه الأمطار",
  tse_networks: "شبكات بورصة طوكيو",
  potable_water_lines: "خطوط المياه الصالحة للشرب",
  fire_suppression_services: "خدمات إخماد الحرائق",
  mainline_services_structures: "الخدمات والهياكل الرئيسية",
  //-------------------------------------------------------------
  underground_utilities: "تحت المرافق الأرضية",
  dry_utilities: "المرافق الجافة",
  foundations: "أسس",
  underground_electrical_works: "الأعمال الكهربائية تحت الأرض",
  underground_telecommunication_works: "أعمال الاتصالات تحت الأرض",
  qaf_qatar_armed_forces: "QAF (القوات المسلحة القطرية)",
  its_intelligent_transport_systems: "أنظمة النقل الذكية (ITS)",
  cable_laying: "مد الكابلات",
  pole_foundation: "مؤسسة القطب",
  mainline_services_structures: "الخط الرئيسي والخدمات والهياكل",
  underground_duct_works: "أعمال مجاري الهواء تحت الأرض",
  street_sign_boards: "اللوحات الإرشادية بالشوارع",
  street_lighting_works: "أعمال إنارة الشوارع",
  steel_fencing: "سياج فولاذي",
  substations: "المحطات الفرعية",
  ////////////////////////////
  road_works: "أعمال الطرق",
  asphalt_milling: "طحن الأسفلت",
  subgrade_works: "أعمال الدرجة الفرعية",
  subbase_works: "أعمال القاعدة الفرعية",
  asphalting_works: "أعمال السفلتة",
  road_marking: "علامات الطريق",
  kerbs_gutters: "الأرصفة والمزاريب",
  parking_bay_side_walk_median_islands_works:
    "أعمال مواقف السيارات والممر الجانبي والوسطى والجزر",
  bus_stop_lanes: "موقف الحافلات والممرات",
  brick_paving_works: "أعمال رصف الطوب",
  ////////////////////////////
  flooring_works: "أعمال الأرضيات",
  epoxy_flooring_works: "أعمال أرضيات الإيبوكسي",
  concrete_flooring: "الأرضيات الخرسانية",
  rubber_flooring: "ارضية مطاطية",
  colored_stamped_concrete_flooring: "الأرضيات الخرسانية الملونة والمختومة",
};

export default arabicDataAr;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../components/context/AppProvider";
import "./viewProjectUser.css";

const ViewProjectUser = () => {
  const { idProject } = useParams();
  const [allDataViewProject, setAllDataViewProject] = useState([]);
  const { direction } = useAppContext();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataViewProject = async () => {
    const formDataViewViewProject = new FormData();
    formDataViewViewProject.append("validation", validation);
    formDataViewViewProject.append("idProject", idProject);

    try {
      const responseViewProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewViewProject,
        }
      );
      const dataViewProject = await responseViewProject.json();
      setAllDataViewProject(dataViewProject);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataViewProject();
  }, []);

  // console.log(allDataViewProject);

  return (
    <div id="view_project_user">
      <div className="view_project_user">
        {allDataViewProject &&
          allDataViewProject.map((item, i) => (
            <div className="view_project_item" key={i}>
              <img
                src={`${LinkLikeBachend}${item.img_project}`}
                alt={item.title_project_en}
              />

              {direction == "ltr" ? (
                <>
                  <p>{item.title_project_en}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description_project_en,
                    }}
                  />
                </>
              ) : (
                <>
                  <p>{item.title_project_ar}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description_project_ar,
                    }}
                  />
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ViewProjectUser;

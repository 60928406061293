import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./updateProject.css";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

const UpdateProject = () => {
  const { idProject } = useParams();
  const [titleProjectEN, setTitleProjectEN] = useState("");
  const [titleProjectAR, setTitleProjectAR] = useState("");
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedUpdateImage, setSelectedUpdateImage] = useState(null);

  const [editorDataEN, setEditorDataEN] = useState(null);
  const [editorDataAR, setEditorDataAR] = useState(null);

  const [editorConfigEN, setEditorConfigEN] = useState({
    placeholder: "description en",
  });
  const [editorConfigAR, setEditorConfigAR] = useState({
    placeholder: "description ar",
    direction: "rtl",
  });

  const handleSendcontentEN = (newContentEN) => {
    setEditorDataEN(newContentEN);
  };
  const handleSendcontentAR = (newContentAR) => {
    setEditorDataAR(newContentAR);
  };
  const handleSendTitleEN = (newTitleEN) => {
    setTitleProjectEN(newTitleEN.target.value);
  };
  const handleSendTitleAR = (newTitleAR) => {
    setTitleProjectAR(newTitleAR.target.value);
  };

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataViewProject = async () => {
    const formDataViewProject = new FormData();
    formDataViewProject.append("validation", validation);
    formDataViewProject.append("idProject", idProject);

    try {
      const responseUpdateProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewProject,
        }
      );
      const dataUpdateProject = await responseUpdateProject.json();

      setTitleProjectEN(dataUpdateProject[0].title_project_en);
      setTitleProjectAR(dataUpdateProject[0].title_project_ar);

      setEditorDataEN(dataUpdateProject[0].description_project_en);
      setEditorDataAR(dataUpdateProject[0].description_project_ar);

      setSelectedUpdateImage(
        `${LinkLikeBachend}${dataUpdateProject[0].img_project}`
      );
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataViewProject();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setSelectedImage(file);
  };

  const imageUrl = selectedImage
    ? URL.createObjectURL(selectedImage)
    : selectedUpdateImage;

  const handleProject_admin = async (e) => {
    e.preventDefault();
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("idProject", idProject);
    FormDataSubmit.append("img_project", selectedImage);
    FormDataSubmit.append("titleProjectEN", titleProjectEN);
    FormDataSubmit.append("titleProjectAR", titleProjectAR);
    FormDataSubmit.append("editorDataEN", editorDataEN);
    FormDataSubmit.append("editorDataAR", editorDataAR);

    const response = await fetch(
      `${LinkLikeBachend}update/update_project.php`,
      {
        method: "POST",
        body: FormDataSubmit,
      }
    );

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        let img_project = document.querySelector("#img_project");
        img_project.value = "";

        toast.success("Data added successfully");
        setDisabledSubmit(false);

        fetchDataViewProject();
      } else if (resultText == "successfully_image") {
        toast.success("The image has been updated");
        setDisabledSubmit(false);
        fetchDataViewProject();
      } else if (resultText == "no_changes") {
        toast.success("The data has not been changed");
        setDisabledSubmit(false);
        fetchDataViewProject();
      } else {
        toast.error("Error when adding data");

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when adding data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledSubmit(false);
    }
  };

  return (
    <div className="project_admin">
      <form
        className="project_form"
        onSubmit={handleProject_admin}
        encType="multipart/form-data"
      >
        <div className="div_img_project">
          <input
            type="file"
            name="img_project"
            id="img_project"
            accept="image/*"
            onChange={handleFileChange}
          />
          {imageUrl && (
            <div>
              <img src={imageUrl} alt="background_img" />
            </div>
          )}
        </div>

        <div className="data_form">
          <div className="label_data_form">
            <label htmlFor="description">title en</label>
            <input
              type="text"
              name="title_project_en"
              id="title_project_en"
              placeholder="title en"
              value={titleProjectEN || ""}
              onChange={handleSendTitleEN}
            />
          </div>

          <div className="label_data_form">
            <label htmlFor="description">title ar</label>
            <input
              type="text"
              name="title_project_ar"
              id="title_project_ar"
              placeholder="title ar"
              value={titleProjectAR || ""}
              onChange={handleSendTitleAR}
            />
          </div>
        </div>

        <div className="data_form">
          <div className="description">
            <label htmlFor="description">description en</label>
            <JoditEditor
              name="description_project_en"
              id="description_project_en"
              placeholder="description en"
              value={editorDataEN || ""}
              onChange={handleSendcontentEN}
              config={editorConfigEN}
            />
          </div>

          <div className="description">
            <label htmlFor="description">description ar</label>
            <JoditEditor
              value={editorDataAR || ""}
              onChange={handleSendcontentAR}
              id="description_project_ar"
              name="description_project_ar"
              placeholder="description ar"
              config={editorConfigAR}
              required
            />
          </div>
        </div>

        <input
          type="submit"
          value="update"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        />
      </form>
    </div>
  );
};

export default UpdateProject;

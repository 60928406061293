import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./projects.css";
import { useTranslation } from "react-i18next";
import { Navigation } from "swiper/modules";
import { useAppContext } from "../../components/context/AppProvider";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const { t } = useTranslation();
  const [allDataViewProject, setAllDataViewProject] = useState([]);
  const { direction } = useAppContext();
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [viewProjectCount, setViewProjectCount] = useState(0);
  const navigate = useNavigate();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataViewProject = async () => {
    const formDataViewViewProject = new FormData();
    formDataViewViewProject.append("validation", validation);

    try {
      const responseViewProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewViewProject,
        }
      );
      const dataViewProject = await responseViewProject.json();
      setAllDataViewProject(dataViewProject);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataViewProjectCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responseViewProjectCount = await fetch(
        `${LinkLikeBachend}read/view_project_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataViewProjectCount = await responseViewProjectCount.text();

      setViewProjectCount(dataViewProjectCount);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataViewProject();
    fetchDataViewProjectCount();
  }, []);

  const handleLoadMore = () => {
    setDisabledLoadMore(true);

    setTimeout(() => {
      setDisabledLoadMore(false);
      navigate("/projects");
    }, 3000);
  };

  const extractText = (text) => {
    const pattern = /<p>.*?<\/p>/;
    const matchResult = pattern.exec(text);

    return (
      matchResult &&
      `${matchResult[0].substring(0, 50)}${
        matchResult[0].length > 50 ? "..." : ""
      }`
    );
  };
  const handelIdProject = (idProject) => {
    navigate(`/project/${idProject}`);
  };

  // console.log(allDataViewProject);
  //   console.log(viewProjectCount);

  return (
    <div id="projects">
      <div className="projects">
        <div className="projects_top">
          <h2>{t("our_projects")}</h2>
          <p>{t("about_top_p")}</p>
        </div>

        <div className="projects_bottom">
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            {allDataViewProject && allDataViewProject.length > 0 ? (
              direction == "ltr" ? (
                allDataViewProject.map((item, i) => (
                  <SwiperSlide
                    key={i}
                    virtualIndex={i}
                    onClick={() => {
                      handelIdProject(item.id);
                    }}
                  >
                    <img
                      src={`${LinkLikeBachend}${item.img_project}`}
                      alt={item.title_project_en}
                    />

                    <div className="data_slider">
                      <h3>{item.title_project_en}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: extractText(item.description_project_en),
                        }}
                      />
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                allDataViewProject.map((item, i) => (
                  <SwiperSlide key={i} virtualIndex={i}>
                    <img
                      src={`${LinkLikeBachend}${item.img_project}`}
                      alt={item.title_project_ar}
                    />

                    <div className="data_slider">
                      <h3>{item.title_project_ar}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: extractText(item.description_project_ar),
                        }}
                      />
                    </div>
                  </SwiperSlide>
                ))
              )
            ) : (
              <p>No ViewProject data available.</p>
            )}
          </Swiper>

          <div className="div_load_more">
            {allDataViewProject &&
              allDataViewProject.length > 0 &&
              parseInt(viewProjectCount) !== allDataViewProject.length && (
                <button
                  className="load_more"
                  onClick={handleLoadMore}
                  disabled={isDisabledLoadMore}
                  style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                >
                  {!isDisabledLoadMore ? t("load_more") : t("loading")}
                </button>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

export const englishDataEn = {
  home: "HOME",
  about_us: "ABOUT US",
  our_services: "OUR SERVICES",
  our_projects: "OUR PROJECTS",
  nam_dam: "DAM",
  trading_contracting: "TRADING &CONTRACTING",
  info_header:
    "is a civil & construction company, it has built a strong reputation for delivering a quality service, on time, and at a competitive price",
  be_touch_cheek: "BE IN TOUCH AND CHEEK",
  about_top_p: "DAM TRADING & CONTRACTING W.L.L",
  about_center_p_1:
    "dam was founded and established in doha-qatar as a small utility company on march 4, 2019.",
  about_center_p_2:
    "dam has since grown into one of the respected and leading utility and infrastructure firms in state of qatar.",
  about_center_p_3:
    "dam is committed to surpassing the expectations of our clients, main contractors and suppliers by developing relationships through integrity, professionalism and service which yields the highest quality construction projects.",
  about_center_p_4:
    "our company team is made up of over-achievers who see to it that our customers receive the very best. our company is quality individuals producing quality projects by professional engineers, excellence is our goal as well as to ensure that our clients achieve their goals.",
  load_more: "Load More",
  loading: "Loading...",
  read_more: "Read more",
  ////////////////////////////
  earth_works: "EARTH WORKS",
  excavation_works: "EXCAVATION WORKS",
  micro_tunneling: "MICRO TUNNELING",
  cut_fill_works: "CUT & FILL WORKS",
  grading_works: "GRADING WORKS",
  ////////////////////////////
  underground_utilities: "UNDER GROUND UTILITIES",
  wet_utilities: "WET UTILITIES",
  foul_sewer_drainage_networks: "FOUL SEWER DRAINAGE NETWORKS",
  storm_water_drainage_networks: "STORM WATER DRAINAGE NETWORKS",
  tse_networks: "TSE NETWORKS",
  potable_water_lines: "POTABLE WATER LINES",
  fire_suppression_services: "FIRE SUPPRESSION SERVICES",
  mainline_services_structures: "MAINLINE SERVICES AND STRUCTURES",
  ////////////////////////////
  dry_utilities: "DRY UTILITIES",
  foundations: "FOUNDATIONS",
  underground_electrical_works: "UNDERGROUND ELECTRICAL WORKS",
  underground_telecommunication_works: "UNDERGROUND TELECOMMUNICATION WORKS",
  qaf_qatar_armed_forces: "QAF (QATAR ARMED FORCES)",
  its_intelligent_transport_systems: "ITS (INTELLIGENT TRANSPORT SYSTEMS)",
  cable_laying: "CABLE LAYING",
  pole_foundation: "POLE FOUNDATION",
  underground_duct_works: "UNDERGROUND DUCT WORKS",
  street_sign_boards: "STREET SIGN BOARDS",
  street_lighting_works: "STREET LIGHTING WORKS",
  steel_fencing: "STEEL FENCING",
  substations: "SUBSTATIONS",
  ////////////////////////////
  road_works: "ROAD WORKS",
  asphalt_milling: "ASPHALT MILLING",
  subgrade_works: "SUBGRADE WORKS",
  subbase_works: "SUBBASE WORKS",
  asphalting_works: "ASPHALTING WORKS",
  road_marking: "ROAD MARKING",
  kerbs_gutters: "KERBS & GUTTERS",
  parking_bay_side_walk_median_islands_works:
    "PARKING BAY, SIDE WALK, MEDIAN & ISLANDS WORKS",
  bus_stop_lanes: "BUS STOP & LANES",
  brick_paving_works: "BRICK PAVING WORKS",
  ////////////////////////////
  flooring_works: "FLOORING WORKS",
  epoxy_flooring_works: "EPOXY FLOORING WORKS",
  concrete_flooring: "CONCRETE FLOORING",
  rubber_flooring: "RUBBER FLOORING",
  colored_stamped_concrete_flooring: "COLORED & STAMPED CONCRETE FLOORING",
};

export default englishDataEn;

import React, { useEffect } from "react";
import NotFound from "./page/notFound/NotFound";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./page/home/Home";
import Authentication from "./admin/Authentication";
import HeaderAdmin from "./admin/dashboard/headerAdmin/HeaderAdmin";
import { useAppContext } from "./components/context/AppProvider";
import ViewProject from "./admin/dashboard/project/viewProject/ViewProject";
import AddProject from "./admin/dashboard/project/AddProject";
import UpdateProject from "./admin/dashboard/project/updateProject/UpdateProject";
import Navbar from "./components/navbar/Navbar";
import AllProjects from "./page/projects/allProjects/AllProjects";
import ViewProjectUser from "./page/projects/viewProject/ViewProjectUser";

const App = () => {
  const { direction, setDirection } = useAppContext();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={`direction_${direction}`} style={{ direction: direction }}>
      <Routes>
        <Route
          path="/"
          element={<Navbar setDirection={setDirection} direction={direction} />}
        >
          <Route path="/" element={<Home />} />
          <Route path="projects" element={<AllProjects />} />
          <Route path="project/:idProject" element={<ViewProjectUser />} />
          <Route path="update-project/:idProject" element={<UpdateProject />} />
        </Route>

        <Route path="/admin" element={<Authentication />} />

        <Route path="/dashboard" element={<HeaderAdmin />}>
          <Route path="/dashboard" element={<ViewProject />} />
          <Route path="add-project" element={<AddProject />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;

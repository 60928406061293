import React from "react";
import Header from "../header/Header";
import About from "../About/About";
import Services from "../services/Services";
import Projects from "../projects/Projects";

const Home = () => {
  return (
    <>
      <Header />
      <About />
      <Services />
      <Projects />
    </>
  );
};

export default Home;
